import { template as template_bd647a3641d94cf690bb28af25eeecde } from "@ember/template-compiler";
const FKLabel = template_bd647a3641d94cf690bb28af25eeecde(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
