import { template as template_82baf47fd1d44150b7c462bbb2315dee } from "@ember/template-compiler";
const WelcomeHeader = template_82baf47fd1d44150b7c462bbb2315dee(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
